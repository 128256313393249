html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}

#calendar {
  width: 100%;
  height: 800px;
  max-height: 90vh;
}